<template>
  <div>
    {{i18n.$t('home')}}
  </div>
</template>

<script>
import { useI18n } from "../../src/i18nPlugin";

export default {
  setup() {
    const i18n = useI18n();
    return{
      i18n
    }
  },
}
</script>
