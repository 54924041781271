<template>
  <div :class="containerClass" @click="onWrapperClick">
    <Toast />
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import { useToast } from "primevue/usetoast";
import { useWsckSetup, useWsckOn } from "../compositions/useWsck";
import { useI18n } from "../i18nPlugin";

import AppTopBar from "./default/AppTopbar.vue";
import AppMenu from "./default/AppMenu.vue";
// import AppConfig from './default/AppConfig.vue';
import AppFooter from "./default/AppFooter.vue";

export default {
  setup() {
    const toast = useToast();
    const i18n = useI18n();
    let prevStatus = "";
    const menu = [
      {
        label: "inventories",
        items: [
          { label: "plants", icon: "ri:plant-line", to: "/plants" },
          { label: "materials", icon: "tabler:box", to: "/materials" },
        ],
      },
      {
        label: "management",
        items: [
          { label: "projects", icon: "ic:round-folder-open", to: "/projects" },
          {
            label: "editor",
            icon: "icon-park-outline:mediaeditor",
            to: "/editor",
          },
          // {
          //   label: "landscaper",
          //   icon: "ic:outline-design-services",
          //   to: "/landscapers",
          // },
        ],
      },
    ];
    function onWckStatus({ status }) {
      if (prevStatus === status) {
        return;
      }
      switch (status) {
        case "OPEN":
          toast.add({
            severity: "success",
            summary: "Connected to HortusConnect",
            life: 3000,
          });
          prevStatus = status;
          break;
        case "CLOSED":
          toast.add({
            severity: "error",
            summary: "Connection with HortusConnect lost",
            life: 3000,
          });
          prevStatus = status;
          break;
      }
    }
    useWsckOn("wsck", "status", onWckStatus);
    const { status } = useWsckSetup();
    return {
      i18n,
      status,
      menu,
    };
  },
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
          "layout-theme-light": this.$appState.theme.startsWith("saga"),
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme ? "images/logo-white.svg" : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive) this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar: AppTopBar,
    AppMenu: AppMenu,
    // 'AppConfig': AppConfig,
    AppFooter: AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";
</style>
