<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <span>Hortus Connect</span>
      <i
        class="pi pi-sync mx-3 text-xl font-bold"
        :class="{
          'pi-circle-fill': status !== 'CONNECTING',
          'pi-sync': status === 'CONNECTING',
          'pi-spin': status === 'CONNECTING',
          'text-pink-500': status === 'CLOSED',
          'text-orange-500': status === 'CONNECTING',
          'text-teal-700': status === 'OPEN',
        }"
      />
    </router-link>
    <button
      class="p-link layout-menu-button layout-topbar-button"
      @click="onMenuToggle"
    >
      <i class="pi pi-bars"></i>
    </button>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li class="flex align-items-center">
        <Icon
          icon="ic:round-language"
          class="mr-2"
          width="24px"
          height="24px"
        />
        <Dropdown
          v-model="lang"
          :options="langs"
          optionLabel="code"
          optionValue="code"
          @change="switchLanguage(lang)"
        />
      </li>
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-calendar"></i>
          <span>{{ i18n.$t("events") }}</span>
        </button>
      </li>
      <li>
        <button class="p-link layout-topbar-button">
          <i class="pi pi-cog"></i>
          <span>{{ i18n.$t("settings") }}</span>
        </button>
      </li>
      <li>
        <button
          class="p-link layout-topbar-button"
          @click="toggle"
          aria-haspopup="true"
          aria-controls="overlay_menu"
        >
          <i class="pi pi-user"></i>
          <span>{{ i18n.$t("profile") }}</span>
        </button>
        <Menu id="overlay_menu" ref="menu" :model="items" :popup="true" />
      </li>
    </ul>
  </div>
</template>

<script>
import router from "@/router";
import { useWsck } from "@/compositions/useWsck";
import { ref } from "vue";
import { useI18n } from "../../../src/i18nPlugin";

export default {
  setup() {
    const i18n = useI18n();
    function switchLanguage(lang) {
      i18n.locale.value = lang;
    }
    const { status } = useWsck();
    const lang = ref();
    const langs = ref([
      { name: "English", code: "en" },
      { name: "French", code: "fr" },
    ]);
    lang.value = i18n.locale.value;
    const menu = ref();
    const items = ref([
      {
        label: i18n.$t("logout"),
        icon: "pi pi-power-off",
        command: () => {
          localStorage.removeItem("pxtoken");
          router.push("/session/logout");
        },
      },
    ]);

    const toggle = (event) => {
      menu.value.toggle(event);
    };
    return {
      status,
      lang,
      langs,
      i18n,
      items,
      menu,
      toggle,
      switchLanguage,
    };
  },
  methods: {
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo-dark.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
