<template>
	<div class="layout-menu-container">
    <div class="flex align-content-center">
      <Avatar :label="user.user.username.slice(0,2).toUpperCase()" size="xlarge" shape="circle"/>
      <div class="flex flex-column ml-4">
        <div class="text-lg">{{ user.user.username }}</div>
        <div class="font-light">{{ user.user.roles.join(', ') }}</div>
      </div>
    </div>
    <Divider />
		<AppSubmenu :items="model" class="layout-menu" :root="true" @menuitem-click="onMenuItemClick" />
	</div>
</template>

<script>
import AppSubmenu from './AppSubmenu';

import { useAuth } from '@compositions/useAuth'

export default {
  props: {
    model: Array
  },
  setup () {
    const { user, isLandscaper } = useAuth()
    return { user, isLandscaper }
  },
  methods: {
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event);
    }
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    }
  },
  components: {
    'AppSubmenu': AppSubmenu
  }
}
</script>